@import '../../style/variables';

.dropdown-item {
  color: $color-primary-dark !important;
  font-size: 15px;
  padding: 0;

  &:hover {
    color: $color-white !important;
    background-color: $color-primary !important;
  }

  &:focus,&:active,&.active {
    color: $color-white !important;
    background-color: $color-primary-dark !important;
  }
}