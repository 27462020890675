@import '../../style/variables';

.navbar-brand {
  >*{
    font-weight: 600;
    color: $color-gray !important;

    &:hover,&:focus {
      text-decoration: none !important;
      color: $color-primary-dark !important;
    }
  }
}

.fixed-top-banner {
  height: 65px;
  left: 0;
  position: static !important;
  right: 0;
  top: 0;
  z-index: 1030;
}

.nav-dropdown-button {
  border: 0 !important;

  &:focus,&:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

.vl {
  border-left: 1px solid;
  border-image: linear-gradient(
    to bottom, 
    rgba(0,0,0,0) 15%,
    $color-gray 15%,
    $color-gray 85%,
    rgba(0,0,0,0) 85%
  );
  border-image-slice: 1;
}

.navbar-nav {
  .nav-item {
    border-bottom: 3px solid rgba(0,0,0,0);
    cursor: pointer;
    max-height: 62px;

    .nav-link {
      color: $color-gray !important;

      &.user {
        margin:0;
      }
    }

    &.active {
      border-bottom: 3px solid $color-primary-dark;

      .nav-link {
        color: $color-primary-dark !important;
      }
    }

    &:hover,&:focus {
      border-bottom: 3px solid $color-primary;

      .nav-link {
        color: $color-primary-dark !important;
      }
    }
  }

  .nav-link {
    margin: 0.5rem;
  }
}

.navbar.seperator {
  border-bottom: 1px solid $color-gray-light;
  margin-bottom: 15px;
}

@media screen and (max-width: $medium-screen-width) {
  .navbar-brand {
    text-overflow: ellipsis;
    max-width: 80%;
    font-size: 15px !important;
  }
}

@media screen and (max-width: $extra-large-screen-width) {
  .navbar-brand {
    text-overflow: ellipsis;
    max-width: 80%;
  }

  .navbar-collapse {
    background: $color-white !important;
    border: 1px solid $color-primary-light;
    margin-top: 5px;
    padding-top: 3px;
    z-index: 1000;

    .dropdown {
      width: 60%;

      .dropdown-menu {
        margin-top: 0;
        position: relative;
      }
    }
  }

  .nav-dropdown-button {
    width: 100%;
    text-align: left !important;
  }

  .vl {
    border-left: none;
  }
}