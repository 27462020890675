@import '../../style/variables';

.breadcrumb-container {
  .breadcrumb-list {
    display: flex;
    padding-left: 18px;
    font-style: italic;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: ">";
    color: $color-gray;
  }

  .breadcrumb-item.active {
    color: $color-gray;
    font-style: normal;
  }
}
