// Colors
$color-primary:             #6fc3db;
$color-primary-light:       #e3eef1;
$color-primary-dark:        #2c3e50;

$color-gray:                #5e5e5e;
$color-gray-light:          #a7a7a7;
$color-gray-dark:           #454545;

$color-white:               #ffffff;
$color-black:               #000000;


$color-success:             #68A072;
$color-success-hover:       #578B60;

$color-error:               #e74c3c;

// Sizes for media queries
$small-screen-width:               576px;
$medium-screen-width:              768px;
$large-screen-width:               992px;
$extra-large-screen-width:         1200px;
