.vertical-padding {
  padding-bottom: 15px;
  padding-top: 15px;
}

.horizontal-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.center-content {
  text-align: center;
}