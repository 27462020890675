@import '../../style/variables';

.card-body {
  padding-top: .2rem !important;
  padding-bottom: .2rem !important;
}

.block_container {
  display: flex;
  justify-content: center;
} 