@import '../style/variables';

$inactive-color: grey;
$success-color: #43ac6a;
$warning-color: #d08002;
$error-color: #f04124;

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-margin {
  margin-top: 98px;

  &.banner {
    margin-top: 130px
  }
}

footer.modal-footer {
  display: flex;
  align-items: center;
}

.alert-container.unread {
  border-left: 4px solid #0a59a3;
}

.close-notification {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 101;
}

th {
  background-color: $color-gray-dark;
  color: $color-white;
  font-weight: normal;
  font-size: small;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 0.3em !important;
}

td {
  color: $color-gray-dark;
  font-weight: normal;
  font-size: small;
}

table.table-bordered{
  border:1px solid $color-gray-light;
  margin-bottom:0px;
}
table.table-bordered > thead > tr > th{
  border:1px solid $color-gray-light;
}
table.table-bordered > tbody > tr > td{
  border:1px solid $color-gray-light;
}

label {
  display: inline;
  user-select: none;
}

textarea.form-control {
  color: $color-primary-dark !important;
  border: 1px solid $color-primary !important;
  border-radius: .3rem !important;
  font-size: 0.9rem;
  padding: 0.4rem;

  &.is-invalid {
    border: 1px solid $color-error !important;
  }
}

input.form-control {
  color: $color-primary-dark !important;
  height: 33px;
  padding: 0rem .4rem;
  font-size: 0.9rem;
  border: 1px solid $color-primary !important;
  border-radius: .3rem !important;
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;

  &.is-invalid {
    border: 1px solid $color-error !important;
  }
}

input[disabled] {
  cursor: not-allowed;
}

.ag-theme-balham .ag-cell-inline-editing {
  height: 100% !important;
}

.cui-banner {
  background-color: $color-black;
  color: $color-white;
  justify-content: center !important;
  height: 20px;

  .cui-banner-dropdown {
    z-index: 8888;
  }
}