@import '../../style/variables';

.date-input {
  cursor: pointer;
  display: inline-block;
  width: calc(50% - 12px);
}

.input-group-prepend-input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@media screen and (max-width: $small-screen-width) {
 .btn-width {
  width: 100%;
 }
}