.app-card {
  background-color: #fff;
  border: 1px solid #c8cacb;
  border-radius: 3px;
  position: relative;

  &.default {
    box-shadow: none;
  }
  
  &.raised {
    box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.18), 0 3px 10px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -3px rgba(0, 0, 0, 0.3);
  }
}