@import '../../style/variables';

$border-radius: 5px;

.accordion {
  .accordion-item:first-child {
    .accordion-heading {
      border-top: 1px solid $color-gray-light;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      &:hover {
        border: 1px solid $color-primary-dark;
      }
    }
  }

  .accordion-item:last-child {
    .accordion-body {
      border-bottom: 1px solid $color-gray-light;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    .accordion-heading {
      border-bottom: 1px solid $color-gray-light;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      &:hover {
        border-bottom: 1px solid $color-primary-dark;
      }

      &.is-open {
        border-radius: 0;
      }
    }
  }
}

.accordion-heading {
  align-content: center;
  border: 1px solid $color-gray-light;
  border-bottom: 1px solid rgba(0,0,0,0);
  cursor: pointer;
  height: 52px;
  justify-content: space-between;
  user-select: none;
  width: 100%;

  > p {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 1rem;
    margin-top: revert;
  }

  &.is-open {
    background: $color-primary-dark;

    > p {
      color: $color-white !important;
    }

    > .accordion-icon {
      color: $color-white;
    }
  }

  &:hover {
    padding-bottom: 1px;
    border: 1px solid $color-primary-dark;
  }
}

.accordion-body {
  border-left: 1px solid $color-gray-light;
  border-right: 1px solid $color-gray-light;
  padding: 10px;
}
