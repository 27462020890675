@import '../../style/variables';

.text {
  font-style:italic;

  &.log {
    color:red;
  }
}

.w-75 {
  width: 75% !important;
}

@media screen and (max-width: $small-screen-width) {
  .w-75 {
    width: 100% !important;
  }
}