@import '../../style/variables';

.dropzone {
  border: 1px dashed $color-gray-light;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  flex-grow: 6;

  &.active {
    border: 1px dashed $color-primary
  }

  p {
    margin: 0;
  }
}

.drag-input {
  align-items: center;
}
