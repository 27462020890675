@import '../../style/variables';

.siteList {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.card-body {
  padding-top: .2rem !important;
  padding-bottom: .2rem !important;
}

.block_container {
  display: flex;
  justify-content: center;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  vertical-align: bottom !important;
}

.mt-1 {
  margin-top: 0px !important;
}

@media screen and (max-width: $small-screen-width) {
  .offset-8,.offset-6,.offset-10{
    margin-left:0% !important;
  }

  .float-right {
    float:none !important;
  }

  .mt-1 {
    margin-top: 2px !important;
  }

  .ml-1 {
    margin-left: 0px !important;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.vertical-align-center {
  vertical-align: bottom !important;
}